import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './pages/Layout';
import Homepage from './pages/Homepage';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import CookieConsent from 'react-cookie-consent';
import WhatsAppButton from './pages/Components/Whatsapp';
import Education from './pages/Education';
import BlogContent from './pages/BlogContent';
import FAQ from './pages/FAQ';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Homepage />} />
          <Route path="hakkimizda" element={<AboutUs />} />
          <Route path="iletisim" element={<ContactUs />} />
          <Route path="education" element={<Education />} />
          <Route path="sss" element={<FAQ />} />
          <Route path="/:slug" element={<BlogContent />} />
        </Route>
      </Routes>
      <WhatsAppButton />
      <CookieConsent
          location="bottom"
          buttonText="Kabul Ediyorum"
          cookieName="cookieConsent"
          style={{ background: "#000" }}
          buttonStyle={{ color: "#000", fontSize: "13px" }}
          expires={3600}>
          Bu web sitesi kullanıcı deneyimini geliştirmek amacıyla çerezler kullanmaktadır. Çerezler, web sitemizin performansını artırmak, size daha iyi bir deneyim sunmak ve web sitemizin işlevselliğini sağlamak için kullanılmaktadır. Çerezler hakkında daha fazla bilgi almak ve tercihlerinizi yönetmek için Çerez Politikası sayfamızı ziyaret edebilirsiniz.
      </CookieConsent>
    </BrowserRouter>
  );
}

export default App;
import React from 'react'
import { useParams } from 'react-router-dom';
import CityData from '../Data/CityData';
import Universities from './Universities';

/// MARK: - Education
const BlogContent = (props) => {
    const mdStyle = "md:p-12"
    const style = "relative h-auto flex flex-col items-center justify-center p-4 text-gray-800 py-16"
    const { comp, className } = { ...{ comp: false, className: `${mdStyle} ${style}` }, ...props };
    const { slug } = useParams(); // Dinamik parametreyi alıyoruz

    const info = CityData[slug]
    return (
        <section className={className}>
            <Universities />
            <div className="text-center mb-8">
                <h2 className="text-4xl font-bold text-primary">{info.title}</h2>
            </div>
            <div className="max-w-screen-lg">
                {
                    info.contents.map((content) => 
                        <div>
                            <h4 className='font-semibold text-center text-lg mb-6'>
                                {content.title}
                            </h4>
                            {
                                content.body.map((text) => 
                                    <p className='text-lg mb-6'>{text}</p>
                                )
                            }
                        </div>
                    )
                }
            </div>
        </section>
    );
};
export default BlogContent
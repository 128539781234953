import React from "react";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaPhoneAlt, FaEnvelope } from "react-icons/fa";

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-gray-200 p-4 md:p-12 py-16">
            <div className="container mx-auto items-start justify-between max-w-screen-lg">
                <div className="flex flex-wrap justify-between md:grid md:grid-cols-3 md:gap-8">
                    {/* Logo & Description */}
                    <div className="w-full mb-6 md:mb-0">
                        <h2 className="text-2xl font-bold text-white font-spartan tracking-widest">ELİT MENTOR</h2>
                        <p className="text-sm text-gray-400 font-kollektif tracking-widest">EĞİTİM DANIŞMANLIĞI</p>
                        <p className="mt-4 text-gray-400">
                        Kuzey Makedonya’da eğitim hayalinizi gerçeğe dönüştürmek için profesyonel danışmanlık sağlıyoruz.
                        </p>
                    </div>

                    {/* Useful Links */}
                    <div className="w-full mb-6 md:mb-0">
                        <h3 className="text-lg font-semibold text-white mb-4">Faydalı Linkler</h3>
                        <ul className="space-y-2">
                            <li><a href="/hakkimizda" className="hover:text-white transition-colors">Hakkımızda</a></li>
                            <li><a href="/education" className="hover:text-white transition-colors">Kuzey Makedonya'da Eğitim</a></li>
                            <li><a href="/sss" className="hover:text-white transition-colors">Sıkça Sorulan Sorular</a></li>
                            <li><a href="/iletisim" className="hover:text-white transition-colors">İletişim</a></li>
                        </ul>
                    </div>

                    {/* Social Media Links */}
                    <div className="w-full">
                        <h3 className="text-lg font-semibold text-white mb-4">Bizi Takip Edin</h3>
                        <div className="flex space-x-4">
                            <a href="https://www.instagram.com/elit_mentordanismanlik/" className="text-gray-400 hover:text-white transition-colors">
                                <FaFacebookF size={24} />
                            </a>
                            <a href="https://www.instagram.com/elit_mentordanismanlik/" className="text-gray-400 hover:text-white transition-colors">
                                <FaInstagram size={24} />
                            </a>
                        </div>
                    </div>
                </div>

                {/* Copyright Section */}
                <div className="mt-8 border-t border-gray-700 pt-6 text-center">
                    <p className="text-sm text-gray-400">
                        &copy; 2024 Elit Mentor Danışmanlık. Tüm hakları saklıdır.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

import React from "react";
import { FaWhatsapp } from "react-icons/fa"; // WhatsApp ikonu için react-icons paketi

const WhatsAppButton = () => {
    return (
        <a
            href="https://wa.me/38972577451"
            className="fixed z-21 bottom-20 left-4 bg-green-500 text-white p-4 rounded-full shadow-lg flex items-center justify-center hover:bg-green-600 transition-colors"
            target="_blank"
            rel="noopener noreferrer"
        >
            <FaWhatsapp size={24} />
        </a>
    );
};

export default WhatsAppButton;
import React, { useState } from "react";
import logo from "../../resources/images/logo.png"
import { Link, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa"; // Hamburger ve kapatma ikonları

const MenuItem = (item) => {
    const selected = useLocation().pathname == item.path

    var style = "block mt-auto p-2 text-center rounded-full";
    var backgroundColor = selected ? "" : ""
    var textColor = selected ? "text-primary" : "text-text"
    var hover = "hover:text-primary"

    return (
        <li key={item.path} className="content-center">
            <Link
                to={item.path}
                className={`${style} ${backgroundColor} ${textColor} ${hover} transition-colors`}>
                {item.title}
            </Link>
        </li >
    );
}

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const MenuItems = [
        { path: "/", title: "Ana Sayfa" },
        { path: "/hakkimizda", title: "Hakkımızda" },
        { path: "/iletisim", title: "İletişim" },
    ]
    
    var mdStyle = "md:flex md:space-x-8 md:relative md:w-auto md:bg-transparent md:top-0 md:opacity-100"
    var style = "text-lg font-medium absolute left-0 w-full bg-background transition-opacity duration-300";
    var opened = isOpen ? "top-24 opacity-100" : "top-[-400px] opacity-0"

    return (
        <nav className="bg-navBackground shadow-md fixed z-20 w-full h-24">
            <div className="container mx-auto h-24 flex justify-between items-center py-4 px-4">
                {/* Logo */}
                <a className="flex items-center" href="/">
                    <img
                        src={logo}
                        alt="Logo"
                        className="h-12 mr-4"
                    />
                    <div>
                        <h1 className="text-xl font-bold text-primary font-spartan tracking-widest">ELİT MENTOR</h1>
                        <p className="text-sm text-secondary font-kollektif tracking-widest">EĞİTİM DANIŞMANLIĞI</p>
                    </div>
                </a>

                {/* Hamburger Menu - Small Screens */}
                <div className="md:hidden flex items-center">
                    <button onClick={toggleMenu}>
                        {isOpen ? (
                            <FaTimes className="text-2xl text-gray-700" />
                        ) : (
                            <FaBars className="text-2xl text-gray-700" />
                        )}
                    </button>
                </div>

                {/* Menu Items */}
                <ul className={`${mdStyle} ${style} ${opened}`}>
                    {
                        MenuItems.map((item) => MenuItem(item))
                    }
                </ul>
            </div>
        </nav>
    );
};
export default Navbar
import React from "react";
import logo from '../resources/images/logo.png'

const AboutUs = (props) => {
    const mdStyle = "md:p-12"
    const style = "relative h-auto flex flex-col items-center justify-center md:p-4 text-gray-800 md:py-16 pb-16"
    const {comp, className} = { ...{comp:false, className:`${mdStyle} ${style}`}, ...props};
    return (
        <section className={className}>
            <h2 className="text-3xl font-bold mb-4 text-primary">Biz Kimiz?</h2>
            <div className="container mx-auto flex flex-wrap md:flex-nowrap items-start justify-between max-w-screen-lg">
                {/* Left Image */}
                <div className="w-full mb-8 md:mb-0">
                    <img 
                        src={logo} 
                        alt="About Us" 
                        className="rounded-sm md:rounded-lg shadow-lg w-full"
                    />
                </div>

                {/* Right Content */}
                <div className={`w-full md:px-4 ${comp ? "":"px-4"}`}>
                    <p className="text-lg mb-6">
                        Elit Mentor Eğitim Danışmanlığı olarak, Kuzey Makedonya’daki üniversitelere kayıt sürecinde öğrencilere profesyonel destek sunuyoruz. Amacımız, öğrencilerin uluslararası geçerliliği olan kaliteli bir eğitim alarak geleceğe güvenle ilerlemelerini sağlamaktır.
                    </p>
                    <p className="text-lg mb-6">
                        Yılların deneyimi ve Kuzey Makedonya’daki üniversitelerle kurduğumuz güçlü bağlar sayesinde, her adımda en uygun eğitim fırsatlarını sunuyoruz. Başvuru ve vize süreçlerinden yerleşime kadar, öğrencilerimizin yanında olarak süreci kolaylaştırıyoruz.
                    </p>
                    <p className="text-lg mb-6">
                        Eğitim yolculuğunuzda, güvenilir rehberiniz olmayı hedefliyoruz.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
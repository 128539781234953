import { Carousel } from 'flowbite-react'
import slider1 from '../../resources/images/slider1.jpeg'
import React from 'react'

const theme = {
    "root": {
        "base": "relative h-full w-full",
        "leftControl": "absolute left-0 top-0 flex h-full items-center justify-center px-4 focus:outline-none",
        "rightControl": "absolute right-0 top-0 flex h-full items-center justify-center px-4 focus:outline-none"
    },
    "indicators": {
        "active": {
            "off": "bg-white/50 hover:bg-white dark:bg-gray-800/50 dark:hover:bg-gray-800",
            "on": "bg-white dark:bg-gray-800"
        },
        "base": "h-3 w-3 rounded-full",
        "wrapper": "absolute bottom-5 left-1/2 flex -translate-x-1/2 space-x-3"
    },
    "item": {
        "base": "absolute left-1/2 top-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
        "wrapper": {
            "off": "w-full flex-shrink-0 transform cursor-default snap-center",
            "on": "w-full flex-shrink-0 transform cursor-grab snap-center"
        }
    },
    "control": {
        "base": "inline-flex h-8 w-8 items-center justify-center rounded-full bg-white/30 group-hover:bg-white/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-gray-800/30 dark:group-hover:bg-gray-800/60 dark:group-focus:ring-gray-800/70 sm:h-10 sm:w-10",
        "icon": "h-5 w-5 text-white dark:text-gray-800 sm:h-6 sm:w-6"
    },
    "scrollContainer": {
        "base": "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-none",
        "snap": "snap-x"
    }
}

const data = [
    {
        id: 1,
        title: "Kuzey Makedonya’da Sınavsız Eğitim",
        subtitle: "Hayalinizdeki Bölüme Kolayca Adım Atın!",
        promotionText: "Kayıtlar Başladı",
        imageUrl: "https://baskayol.com/wp-content/uploads/2021/12/uskup-gezi-rehberi.jpg"
    },
    {
        id: 2,
        title: "Kuzey Makedonya'da Eğitim",
        subtitle: "Kuzey Makedonya'da İstediğiniz Bölümde Sınavsız Okuma Fırsatı",
        promotionText: "Kayıtlar Başladı",
        imageUrl: slider1
    }
]

const SliderItem = (item) => {
    return (
        <div className="relative flex h-full items-center justify-center bg-gray-400 dark:bg-gray-700 dark:text-white">
            <img className="object-cover h-full w-full brightness-50" src={item.imageUrl} alt="..." />
            <div className="absolute text-center text-white">
                <h5 className="text-xl font-semibold">{item.title}</h5>
                <h1 className="text-4xl font-bold mt-2">{item.subtitle}</h1>
                <p className="text-lg mt-4">{item.promotionText}</p>
                <div className="flex justify-center mt-6 space-x-4 px-12">
                    <a href="/iletisim" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Hemen İletişime Geç</a>
                </div>
            </div>
        </div>
    )
}

export default function HomeSlider() {
  return (
    <div className="md:sticky relative md:top-24 h-[calc(100vh-6rem)] w-full overflow-hidden">
        <Carousel className='w-screen' theme={theme} pauseOnHover slideInterval={3000}>
            {
                data.map((item) => SliderItem(item))
            }
        </Carousel>
    </div>
  )
}

import React from "react";
import HomeSlider from "./Components/HomeSlider";
import Universities from "./Universities";
import Departments from "./Departments";
import ContactUs from "./ContactUs";
import AboutUs from "./AboutUs";

const Homepage = () => {
    const sticky = "md:sticky md:top-24"
    const style = "relative h-auto flex flex-col items-center justify-center p-4 text-gray-800 py-16 md:p-12"
    return (
        <div className="relative">
            <HomeSlider />
            <Universities className={`${style} bg-background`} comp={true} />
            <Departments className={`${style} bg-gray-100`} comp={true} />
            <AboutUs className={`${style} bg-background`} comp={true} />
            <ContactUs className={`${style} bg-gray-100`} comp={true} />
        </div>
    );
};

export default Homepage;
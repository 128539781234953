import React from 'react'
import Universities from './Universities';
import Departments from './Departments';

/// MARK: - Education
const Education = () => {
    return (
        <div className="relative">
            <Universities />
            <Departments />
        </div>
    );
};
export default Education
const CityData = {
    "uskup": {
        title: "Üsküp",
        contents: [
            {
                title: "",
                body: [
                    "Üsküp, Kuzey Makedonya'nın başkenti ve en büyük şehridir. Tarihi MÖ 4. yüzyıla kadar uzanır ve o dönemde Roma İmparatorluğu'nun bir parçası olarak stratejik bir öneme sahipti. Üsküp, 6. yüzyılda Bizans İmparatorluğu'nun egemenliğine geçti ve Slav kabilelerinin göçleriyle Slavlaşmaya başladı. 1392'de Osmanlı İmparatorluğu tarafından fethedildi ve 500 yılı aşkın bir süre Osmanlı yönetimi altında kaldı. Bu dönemde şehir, önemli bir ticaret ve kültür merkezi haline geldi; camiler, hamamlar ve diğer Osmanlı mimarisi örnekleri inşa edildi.",
                    "1963 yılında Üsküp, büyük bir depremle sarsıldı ve şehir ciddi şekilde zarar gördü. Ancak, yeniden inşa süreci hızla başlatıldı ve şehir, modern binalar ile geleneksel Osmanlı mimarisini birleştiren bir yapıya kavuştu. Günümüzde Üsküp, hem tarihi dokusunu hem de modern gelişimini sürdüren, Balkanlar'ın önemli bir kültürel ve ekonomik merkezi olarak varlığını devam ettiriyor. Şehir, Vardar Nehri kıyısındaki köprüleri, heykelleri ve müzeleriyle turistlerin ilgisini çeken bir destinasyon haline gelmiştir."
                ]
            },
            {
                title: "Üsküp'te Yaşam",
                body: [
                    "Üsküp, dinamik ve kozmopolit bir yaşam sunar. Şehir, çeşitli kültürel etkinlikler, festivaller ve sosyal aktivitelerle doludur. Günümüzde Üsküp, modern alışveriş merkezleri, restoranlar ve kafelerle dolu bir yaşam alanı sunar. Ayrıca, şehirdeki üniversiteler ve eğitim kurumları, uluslararası öğrenciler için cazip bir ortam sağlar. Üsküp'te yaşam, tarih ve modernitenin harmanlandığı bir deneyim sunar."
                ]
            },
            {
                title: "Üsküp'te Eğitim",
                body: [
                    "Üsküp, Kuzey Makedonya'nın eğitim merkezi olarak öne çıkar. Şehirdeki üniversiteler, uluslararası standartlarda eğitim sunar ve birçok farklı alanda akademik programlar sunar. Üsküp'teki üniversiteler arasında en prestijli olanları Ss. Cyril and Methodius Üniversitesi ve International Balkan University'dir. Bu üniversiteler, öğrencilere geniş bir akademik yelpaze ve uluslararası bir eğitim ortamı sunar."
                ]
            },
            {
                title: "Tarihçe",
                body: [
                    "Üsküp, MÖ 4. yüzyılda kuruldu ve Roma İmparatorluğu'nun bir parçası olarak stratejik bir öneme sahipti. Şehir, 6. yüzyılda Bizans İmparatorluğu'nun egemenliğine geçti ve Slav kabilelerinin göçleriyle Slavlaşmaya başladı. 1392'de Osmanlı İmparatorluğu tarafından fethedildi ve Osmanlı yönetimi altında 500 yıl boyunca gelişti. Osmanlı döneminde şehir önemli bir ticaret ve kültür merkezi haline geldi. 1963'te büyük bir deprem yaşandı ve şehir ciddi şekilde zarar gördü. Ancak, yeniden inşa süreciyle Üsküp, modern ve tarihi unsurları bir araya getiren bir şehir haline geldi."
                ]
            }
        ]
    },
    "istip": {
        title: "İştip",
        contents: [
            {
                title: "",
                body: [
                    "İştip, Kuzey Makedonya'nın doğusunda yer alan bir şehir olup, tarihi ve kültürel açıdan zengin bir geçmişe sahiptir. Şehir, Osmanlı döneminden kalma birçok yapıyı barındırır ve bu dönemde önemli bir ticaret merkezi olarak bilinir. İştip, doğal güzellikleri ve tarihi dokusuyla turistlerin ilgisini çeken bir yerleşim yeridir. Ayrıca, şehirde birçok geleneksel el sanatları ve zanaatkarlar bulunmaktadır."
                ]
            },
            {
                title: "İştip'te Yaşam",
                body: [
                    "İştip, sakin bir yaşam sunan, huzurlu bir şehir olarak bilinir. Şehir, doğal güzellikleri ve tarihi yapılarıyla dikkat çeker. İştip'te yaşam, köklü bir tarih ve kültürel mirasın bir parçası olmayı sağlar. Şehir, yerel pazarlar, geleneksel restoranlar ve sosyal etkinliklerle doludur. İştip'te yaşam, yerel kültürü deneyimlemek isteyenler için ideal bir ortam sunar."
                ]
            },
            {
                title: "İştip'te Eğitim",
                body: [
                    "İştip, eğitim açısından çeşitli fırsatlar sunar. Şehirdeki eğitim kurumları, öğrencilere geniş bir akademik yelpaze sunar. Ayrıca, İştip'te bulunan eğitim kurumları, öğrencilere uluslararası bir eğitim deneyimi sağlamak amacıyla çeşitli programlar sunar. Eğitim kurumları, öğrencilere kaliteli bir eğitim sunma amacı taşır."
                ]
            },
            {
                title: "Tarihçe",
                body: [
                    "İştip'in tarihi, Osmanlı dönemine kadar uzanır. Şehir, bu dönemde önemli bir ticaret merkezi olarak bilinir. Osmanlı döneminde, İştip'te birçok cami, hamam ve diğer yapılar inşa edilmiştir. Şehir, tarihi boyunca çeşitli medeniyetlerin etkisi altında kalmış ve bu tarihsel miras günümüze kadar gelmiştir. İştip, tarih ve kültürel zenginlikleriyle dikkat çeken bir şehir olarak tanınır."
                ]
            }
        ]
    },
    "kalkandelen": {
        title: "Kalkandelen",
        contents: [
            {
                title: "",
                body: [
                    "Kalkandelen, Kuzey Makedonya'nın batısında bulunan tarihi bir şehirdir. Osmanlı döneminde önemli bir yerleşim yeri olan Kalkandelen, bu dönemde birçok tarihi yapı ve kültürel miras barındırmıştır. Şehir, zengin bir tarihi geçmişe sahip olup, Osmanlı döneminden kalma camiler, medreseler ve diğer yapılar ile dikkat çeker. Ayrıca, Kalkandelen'in doğal güzellikleri de oldukça etkileyicidir."
                ]
            },
            {
                title: "Kalkandelen'de Yaşam",
                body: [
                    "Kalkandelen, huzurlu bir yaşam ortamı sunar ve geleneksel Makedon kültürünü deneyimleme fırsatı sağlar. Şehir, doğal güzellikleri ve tarihi yapılarıyla dikkat çeker. Kalkandelen'de yaşam, sakin ve köklü bir kültürel deneyim sunar. Şehir, yerel pazarlar, geleneksel restoranlar ve sosyal etkinliklerle doludur."
                ]
            },
            {
                title: "Kalkandelen'de Eğitim",
                body: [
                    "Kalkandelen, eğitim açısından çeşitli fırsatlar sunar. Şehirdeki eğitim kurumları, öğrencilere geniş bir akademik yelpaze sunar. Ayrıca, Kalkandelen'de bulunan eğitim kurumları, öğrencilere kaliteli bir eğitim deneyimi sağlamak amacıyla çeşitli programlar sunar. Eğitim kurumları, öğrencilere uluslararası standartlarda eğitim sunma amacı taşır."
                ]
            },
            {
                title: "Tarihçe",
                body: [
                    "Kalkandelen'in tarihi, Osmanlı dönemine kadar uzanır. Şehir, Osmanlı döneminde önemli bir yerleşim yeri olarak bilinir ve bu dönemde birçok tarihi yapı inşa edilmiştir. Kalkandelen, tarih boyunca çeşitli medeniyetlerin etkisi altında kalmış ve bu tarihsel miras günümüze kadar gelmiştir. Şehir, tarihi ve kültürel zenginlikleriyle dikkat çeken bir yerleşim yeridir."
                ]
            }
        ]
    },
    "ohri": {
        title: "Ohri",
        contents: [
            {
                title: "",
                body: [
                    "Ohri, Kuzey Makedonya'nın güneyinde bulunan tarihi bir şehir olup, bölgenin kültürel ve tarihsel merkezi olarak bilinir. Şehir, özellikle Orta Çağ'dan kalma yapıları, tarihi kiliseleri ve güzel gölü ile ünlüdür. Ohri, UNESCO Dünya Mirası listesinde yer alan tarihi bir bölge olarak, zengin bir kültürel mirasa ve tarihi dokuya sahiptir."
                ]
            },
            {
                title: "Ohri'de Yaşam",
                body: [
                    "Ohri, sakin ve huzurlu bir yaşam ortamı sunar. Şehir, doğal güzellikleri, tarihi yapıları ve kültürel etkinlikleri ile dikkat çeker. Ohri'de yaşam, geleneksel Makedon yaşam tarzını deneyimleme fırsatı sağlar. Şehirde, tarihi mekanların yanı sıra modern sosyal ve kültürel olanaklar da mevcuttur."
                ]
            },
            {
                title: "Ohri'de Eğitim",
                body: [
                    "Ohri, eğitim açısından çeşitli fırsatlar sunar. Şehirdeki eğitim kurumları, öğrencilere geniş bir akademik yelpaze sunar ve uluslararası standartlarda eğitim sağlar. Ayrıca, Ohri'deki eğitim kurumları, öğrencilere kaliteli bir eğitim deneyimi sağlamak amacıyla çeşitli programlar sunar."
                ]
            },
            {
                title: "Tarihçe",
                body: [
                    "Ohri'nin tarihi, antik dönemlere kadar uzanır. Şehir, Orta Çağ'dan kalma birçok tarihi yapıya ve kültürel mirasa sahiptir. Ohri, Bizans, Osmanlı ve diğer medeniyetlerin etkisi altında kalmış bir şehir olarak bilinir. UNESCO tarafından Dünya Mirası olarak kabul edilen şehir, tarihi ve kültürel zenginlikleriyle dikkat çeker."
                ]
            }
        ]
    },
    "kriva": {
        title: "Kriva Palanka",
        contents: [
            {
                title: "",
                body: [
                    "Kriva Palanka, Kuzey Makedonya'nın kuzeydoğusunda yer alan bir şehir olup, doğal güzellikleri ve tarihi yapılarıyla tanınır. Şehir, tarihi boyunca çeşitli medeniyetlerin etkisi altında kalmış ve bu etkiler, şehrin kültürel zenginliğine katkıda bulunmuştur. Kriva Palanka, özellikle doğal güzellikleri ve tarihi mirası ile turistlerin ilgisini çeken bir bölgedir."
                ]
            },
            {
                title: "Kriva Palanka'da Yaşam",
                body: [
                    "Kriva Palanka, doğal güzellikleri ve tarihi dokusuyla sakin bir yaşam sunar. Şehir, geleneksel Makedon yaşam tarzını deneyimleme fırsatı sağlar. Kriva Palanka'da yaşam, huzurlu ve köklü bir kültürel deneyim sunar. Şehir, yerel pazarlar, geleneksel restoranlar ve sosyal etkinliklerle doludur."
                ]
            },
            {
                title: "Kriva Palanka'da Eğitim",
                body: [
                    "Kriva Palanka, eğitim açısından çeşitli fırsatlar sunar. Şehirdeki eğitim kurumları, öğrencilere geniş bir akademik yelpaze sunar ve uluslararası standartlarda eğitim sağlar. Ayrıca, Kriva Palanka'daki eğitim kurumları, öğrencilere kaliteli bir eğitim deneyimi sağlamak amacıyla çeşitli programlar sunar."
                ]
            },
            {
                title: "Tarihçe",
                body: [
                    "Kriva Palanka'nın tarihi, antik dönemlere kadar uzanır. Şehir, tarihi boyunca çeşitli medeniyetlerin etkisi altında kalmış ve bu etkiler, şehrin kültürel mirasına katkıda bulunmuştur. Kriva Palanka, tarih boyunca önemli bir yerleşim yeri olarak bilinir ve tarihi yapılarıyla dikkat çeker."
                ]
            }
        ]
    },
    "manastir": {
        title: "Manastır",
        contents: [
            {
                title: "",
                body: [
                    "Manastır, Kuzey Makedonya'nın güneybatısında bulunan bir şehir olup, tarihi ve kültürel açıdan zengin bir geçmişe sahiptir. Şehir, Osmanlı döneminden kalma birçok yapıyı barındırır ve bu dönemde önemli bir ticaret merkezi olarak bilinir. Manastır, ayrıca çeşitli kültürel etkinlikler ve festivaller ile tanınır."
                ]
            },
            {
                title: "Manastır'da Yaşam",
                body: [
                    "Manastır, tarihi ve kültürel zenginlikleri ile dikkat çeken bir yaşam sunar. Şehir, doğal güzellikler, tarihi yapılar ve sosyal etkinliklerle doludur. Manastır'da yaşam, köklü bir kültürel deneyim sunar ve şehirdeki çeşitli etkinlikler ve sosyal olanaklar, yerel yaşamı daha canlı hale getirir."
                ]
            },
            {
                title: "Manastır'da Eğitim",
                body: [
                    "Manastır, eğitim açısından çeşitli fırsatlar sunar. Şehirdeki üniversiteler ve eğitim kurumları, öğrencilere geniş bir akademik yelpaze sunar ve uluslararası standartlarda eğitim sağlar. Manastır'daki eğitim kurumları, öğrencilere kaliteli bir eğitim deneyimi sunma amacı taşır."
                ]
            },
            {
                title: "Tarihçe",
                body: [
                    "Manastır'ın tarihi, Osmanlı dönemine kadar uzanır. Şehir, Osmanlı döneminde önemli bir ticaret merkezi olarak bilinir ve bu dönemde birçok tarihi yapı inşa edilmiştir. Manastır, tarih boyunca çeşitli medeniyetlerin etkisi altında kalmış ve bu tarihsel miras günümüze kadar gelmiştir."
                ]
            }
        ]
    }
};


export default CityData

import React, { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const faqs = [
    {
        question: 'Kuzey Makedonya’da eğitim almak için nasıl başvurabilirim?',
        answer: <p>Başvurularınızı web sitemizdeki başvuru formu üzerinden yapabilirsiniz. Alternatif olarak, doğrudan bizimle iletişime geçerek detaylı bilgi alabilirsiniz.</p>
    },
    {
        question: 'Vize işlemleri nasıl yürütülüyor?',
        answer: <p>Vize işlemleri hakkında size rehberlik ediyoruz. Belgelerinizin hazırlanmasından randevu alımına kadar her aşamada destek sağlıyoruz.</p>
    },
    {
        question: 'Eğitim danışmanlık hizmetleriniz neleri kapsıyor?',
        answer: <p>Hizmetlerimiz, üniversite seçiminden başvuru süreçlerine, vize işlemlerinden konaklama önerilerine kadar geniş bir yelpazeyi kapsar.</p>
    },
    {
        question: 'Kuzey Makedonya’daki üniversiteler hakkında nasıl bilgi alabilirim?',
        answer: <p>Kuzey Makedonya’daki üniversiteler hakkında detaylı bilgi almak için sitemizdeki üniversite rehberine göz atabilir veya doğrudan bizimle iletişime geçebilirsiniz.</p>
    },
    {
        question: 'Kuzey Makedonya’da yaşam maliyetleri nasıldır?',
        answer: <p>Kuzey Makedonya’da yaşam maliyetleri, Avrupa genelinde oldukça uygun seviyelerde olup, konaklama, yiyecek ve diğer günlük harcamalar bütçenize göre değişiklik gösterebilir.</p>
    },
    {
        question: 'Eğitim için burs olanakları mevcut mu?',
        answer: <p>Kuzey Makedonya’daki bazı üniversiteler çeşitli burs olanakları sunmaktadır. Burs başvuruları ve şartları hakkında detaylı bilgi için bizimle iletişime geçebilirsiniz.</p>
    },
    {
        question: 'Dil yeterlilik sınavı şart mı?',
        answer: <p>Bazı programlar için İngilizce veya yerel dilde dil yeterlilik sınavı gerekebilir. İlgilendiğiniz programın şartlarını öğrenmek için bizimle iletişime geçebilirsiniz.</p>
    },
    {
        question: 'Konaklama seçenekleri nelerdir?',
        answer: <p>Kuzey Makedonya’da üniversite yurtları, özel öğrenci apartmanları ve kiralık daireler gibi çeşitli konaklama seçenekleri bulunmaktadır. Size uygun en iyi seçeneği bulmanıza yardımcı olabiliriz.</p>
    },
    {
        question: 'Kuzey Makedonya’da sağlık sigortası nasıl yapılır?',
        answer: <p>Sağlık sigortası işlemleri konusunda size rehberlik ediyoruz. Sağlık sigortası seçeneği ve gereksinimleri hakkında detaylı bilgi almak için bizimle iletişime geçebilirsiniz.</p>
    },
    {
        question: 'Eğitim sürecinde iş bulma olanakları var mı?',
        answer: <p>Kuzey Makedonya’da eğitim sürecinde öğrencilere çeşitli iş fırsatları sunulmaktadır. Çalışma izinleri ve iş olanakları hakkında bilgi için bizimle iletişime geçebilirsiniz.</p>
    },
    {
        question: 'Kuzey Makedonya’daki bölümler hakkında detaylı bilgi alabilir miyim?',
        answer: (
            <div>
                <p>Kuzey Makedonya’daki üniversitelerde geniş bir akademik program yelpazesi sunulmaktadır. İşte bazı önemli bölümler ve detaylı açıklamaları:</p>
                
                <h3 className="text-xl font-semibold mt-4">İşletme ve Ekonomi</h3>
                <p>İşletme ve Ekonomi bölümleri, yönetim, finans, pazarlama ve uluslararası ticaret gibi konular üzerinde yoğunlaşır. Öğrenciler, iş dünyasında başarılı olabilmek için gerekli olan analitik düşünme, stratejik planlama ve yönetim becerilerini geliştirirler. Ayrıca, girişimcilik ve liderlik konularında da eğitim alarak kendi işlerini kurma veya büyük şirketlerde üst düzey pozisyonlarda çalışma fırsatı bulurlar.</p>
                
                <h3 className="text-xl font-semibold mt-4">Mühendislik</h3>
                <p>Mühendislik bölümleri, inşaat, bilgisayar, elektronik, makine ve kimya mühendisliği gibi çeşitli alanlarda eğitim sunar. Bu bölümler, modern laboratuvarlar ve uygulamalı projelerle desteklenen eğitimler sunar. Öğrenciler, teknik bilgi ve becerilerini geliştirirken aynı zamanda endüstri deneyimi kazanmalarını sağlayacak staj ve proje fırsatlarına erişebilirler.</p>
                
                <h3 className="text-xl font-semibold mt-4">Tıp</h3>
                <p>Tıp bölümleri, temel tıp bilimleri, klinik uygulamalar ve hasta bakımı konularında eğitim verir. Öğrenciler, teorik bilgi ile birlikte pratik deneyim kazandıkları klinik rotasyonlara katılırlar. Ayrıca, sağlık yönetimi ve halk sağlığı gibi yan alanlarda da eğitim alarak geniş bir tıp bilgisi ve uygulama becerisi kazanırlar.</p>
                
                <h3 className="text-xl font-semibold mt-4">Hukuk</h3>
                <p>Hukuk bölümleri, anayasa hukuku, ceza hukuku, medeni hukuk ve uluslararası hukuk gibi konular üzerinde durur. Öğrenciler, hukuk sistemini ve yasal süreçleri anlamakla kalmaz, aynı zamanda müvekkil temsilciliği, dava hazırlığı ve hukuki araştırma konularında da eğitim alırlar. Ayrıca, çeşitli hukuk stajları ve pratiği ile gerçek dünya deneyimi elde ederler.</p>
                
                <h3 className="text-xl font-semibold mt-4">Sosyal Bilimler</h3>
                <p>Sosyal Bilimler bölümleri, psikoloji, sosyoloji, antropoloji ve iletişim gibi konuları kapsar. Bu programlar, insan davranışlarını ve toplumsal yapıları anlamak için derinlemesine analiz yapar. Öğrenciler, araştırma yöntemleri, veri analizi ve toplumsal sorunların çözümü konusunda bilgi edinirler.</p>
                
                <h3 className="text-xl font-semibold mt-4">Sanat ve Tasarım</h3>
                <p>Sanat ve Tasarım bölümleri, görsel sanatlar, grafik tasarım, endüstriyel tasarım ve moda tasarımı gibi alanları içerir. Öğrenciler, yaratıcı düşünme, tasarım süreçleri ve teknik beceriler konusunda eğitim alırken, kendi projelerini geliştirir ve portföylerini oluştururlar. Ayrıca, sanat galerileri ve tasarım stüdyolarında uygulamalı deneyim kazanma fırsatları sunulur.</p>
                
                <h3 className="text-xl font-semibold mt-4">Bilgisayar Bilimleri</h3>
                <p>Bilgisayar Bilimleri bölümleri, yazılım geliştirme, veri bilimi, yapay zeka ve siber güvenlik gibi konuları kapsar. Öğrenciler, programlama dilleri, algoritmalar ve sistem tasarımı konularında eğitim alırken, teknoloji dünyasındaki en son gelişmeleri takip etme fırsatı bulurlar. Ayrıca, projeler ve stajlarla pratik deneyim kazanırlar.</p>
                
                <h3 className="text-xl font-semibold mt-4">İnşaat ve Mimarlık</h3>
                <p>İnşaat ve Mimarlık bölümleri, yapı tasarımı, inşaat yönetimi ve şehir planlama gibi konular üzerinde yoğunlaşır. Öğrenciler, yapı mühendisliği ve mimari tasarım prensipleri hakkında bilgi edinirken, projelerde ve saha çalışmalarında aktif rol alırlar. Ayrıca, çeşitli yapı projeleri ve stajlarla uygulamalı deneyim kazanırlar.</p>
                
                <h3 className="text-xl font-semibold mt-4">Doğa Bilimleri</h3>
                <p>Doğa Bilimleri bölümleri, biyoloji, kimya, fizik ve çevre bilimleri gibi konuları içerir. Öğrenciler, laboratuvar çalışmaları ve saha araştırmaları ile temel bilimsel prensipleri öğrenir ve çeşitli deneyler yaparak bilgi ve becerilerini geliştirirler. Ayrıca, bilimsel araştırma ve analiz konularında da eğitim alırlar.</p>
                
                <h3 className="text-xl font-semibold mt-4">Eğitim</h3>
                <p>Eğitim bölümleri, pedagojik yöntemler, öğretim stratejileri ve eğitim psikolojisi gibi konular üzerinde durur. Öğrenciler, öğretmenlik ve eğitim yönetimi konularında eğitim alarak sınıf yönetimi, müfredat geliştirme ve öğrenci değerlendirme gibi beceriler kazanırlar. Ayrıca, çeşitli öğretim uygulamaları ve staj fırsatları sunulur.</p>
            </div>
        )
    }
];

const FAQ = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const [activeIndex, setActiveIndex] = useState(isMobile ? null : 0);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleClick = (index) => {
        handleScrollToTop()

        if (isMobile) {
            setActiveIndex(index === activeIndex ? null: index);
        } else {
            setActiveIndex(index);
        }
    };

    return (
        <div className='flex justify-center mb-6'>
            <div className='flex flex-col items-center justify-center'>
                <h2 className="text-2xl font-bold mb-6 mt-6 text-gray-800">Sıkça Sorulan Sorular</h2>
                <div className='flex flex-col md:flex-row justify-center p-4 max-w-screen-lg'>
                    {/* Sol Panel (Desktop) */}
                    {!isMobile && (
                        <div className="w-full md:w-2/5">
                            <ul className="space-y-2">
                                {faqs.map((faq, index) => (
                                    <li key={index}>
                                        <button
                                            className={`block w-full text-left p-4 rounded-lg transition-colors duration-300 font-medium ${activeIndex === index ? 'bg-gray-400 text-white' : 'bg-white text-gray-600'
                                                } hover:bg-gray-50 hover:text-primary`}
                                            onClick={() => handleClick(index)}
                                        >
                                            {faq.question}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {/* Sağ Panel */}
                    <div className={`w-full md:w-3/4 px-4 ${isMobile ? 'mt-4' : ''}`}>
                        {isMobile ? (
                            faqs.map((faq, index) => (
                                <div key={index} className="bg-white p-4 border border-gray-200 rounded-lg shadow-md mb-4">
                                    <button
                                        className="w-full text-left font-semibold text-gray-800 p-4 rounded-lg"
                                        onClick={() => handleClick(index)}
                                    >
                                        {faq.question}
                                    </button>
                                    {activeIndex === index && (
                                        <div className="mt-2 text-gray-600">
                                            {faq.answer}
                                        </div>
                                    )}
                                </div>
                            ))
                        ) : (
                            activeIndex !== null && (
                                <div className="bg-white p-6 border border-gray-200 rounded-lg shadow-md">
                                    <h3 className="text-xl font-semibold mb-4 text-gray-800">{faqs[activeIndex].question}</h3>
                                    <p className="text-gray-600">{faqs[activeIndex].answer}</p>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQ;
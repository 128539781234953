import React from 'react'
import istip from '../resources/images/istip.jpg'
import kalkandelen from '../resources/images/kalkandelen.jpg'
import ohri from '../resources/images/ohri.jpg'
import uskup from '../resources/images/uskup.jpg'

/// MARK: - Universities
const Universities = (props) => {
    const mdStyle = "md:p-12"
    const style = "relative h-auto flex flex-col items-center justify-center p-4 text-gray-800 py-16"
    const {comp, className} = { ...{comp:false, className:`${mdStyle} ${style}`}, ...props};

    const imageLinkClass = "absolute inset-0 flex items-center justify-center bg-black bg-opacity-60 p-4 rounded text-white text-3xl font-semibold opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out pointer-events-none group-hover:pointer-events-auto"
    return (
        <section className={className}>
            <div className="text-center mb-8">
                <h2 className="text-4xl font-bold text-primary">Kuzey Makedonya’da Eğitim</h2>
                <p className="text-xl text-secondary mt-2">Avrupa’nın Kalbinde Kaliteli ve Fırsatlarla Dolu Bir Eğitim Deneyimi</p>
            </div>
            <div className="grid grid-flow-row grid-flow-col gap-1 max-w-screen-lg">
                <div className="row-span-1 md:col-span-12 col-span-6 relative group md:rounded-lg rounded-sm overflow-hidden shadow-lg">
                    <img
                        src={uskup}
                        alt="University"
                        className="w-full h-full object-cover"
                    />
                    <a className={imageLinkClass} href='/uskup'>Üsküp</a>
                </div>
                <div className="row-span-1 md:col-span-6 col-span-6 relative group md:rounded-lg rounded-sm overflow-hidden shadow-lg">
                    <img
                        src={kalkandelen}
                        alt="University"
                        className="w-full h-full object-cover"
                    />
                    <a className={imageLinkClass} href='/kalkandelen'>Kalkandelen</a>
                </div>
                <div className="row-span-1 md:col-span-6 col-span-6 relative group md:rounded-lg rounded-sm overflow-hidden shadow-lg">
                    <img
                        src={ohri}
                        alt="University"
                        className="w-full h-full object-cover"
                    />
                    <a className={imageLinkClass} href='/ohri'>Ohri</a>
                </div>
                <div className="row-span-3 md:row-span-2 md:col-span-12 col-span-12 relative group md:rounded-lg rounded-sm overflow-hidden shadow-lg">
                    <img
                        src={istip}
                        alt="University"
                        className="w-full h-full object-cover"
                    />
                    <a className={imageLinkClass} href='/istip'>İştip</a>
                </div>
            </div>
        </section>
    )
}
export default Universities
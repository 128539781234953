import React from 'react'


const Departments = (props) => {
    const mdStyle = "md:p-12"
    const style = "relative h-auto flex flex-col items-center justify-center p-4 text-gray-800 py-16"
    const { comp, className } = { ...{ comp: false, className: `${mdStyle} ${style}` }, ...props };
    return (
        <section className={className}>
            <div className="text-center mb-8">
                <h2 className="text-4xl font-bold text-primary">Sınavsız Hayalindeki Bölümde Okuma Fırsatı</h2>
                <p className="text-xl text-secondary mt-2">Geleceğini inşa etmek için hayalindeki bölüme kolayca adım at; sınavsız üniversite kaydı avantajını yakala!</p>
            </div>
            <div className="max-w-screen-lg">
                <h4 className='font-semibold text-center text-lg mb-6'>
                    Kuzey Makedonya’da Eğitim: Geleceğinizi Şekillendirin!
                </h4>
                <p className='text-lg mb-6'>
                    Hayalinizdeki eğitimi uluslararası standartlarda almak mı istiyorsunuz? Elit Mentor Eğitim Danışmanlığı olarak, Kuzey Makedonya’daki üniversitelerde size ideal eğitim fırsatlarını sunuyoruz. Mühendislikten sosyal bilimlere, tıptan sanatlara kadar geniş bir bölüm yelpazesi ile akademik hedeflerinize ulaşmanız artık çok daha kolay. Modern kampüsler, deneyimli eğitmenler ve uluslararası geçerliliğe sahip diploma programlarıyla geleceğinize sağlam bir temel atın.
                </p>
                <p className='text-lg mb-6'>
                    Kuzey Makedonya’nın kültürel zenginlikleri ve uygun yaşam maliyetleri, eğitim hayatınızı hem verimli hem de keyifli hale getirir. Elit Mentor Eğitim Danışmanlığı olarak, başvuru süreçlerinden vize işlemlerine kadar her aşamada yanınızdayız. Sizin için en uygun üniversiteyi ve bölümü bulmak için uzman kadromuzla hizmetinizdeyiz. Şimdi bizimle iletişime geçin ve Kuzey Makedonya’da başarılı bir eğitim yolculuğuna adım atın!
                </p>
            </div>
        </section>
    );
};

export default Departments;
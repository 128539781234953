import React from "react";
import Footer from "./Components/Footer";
import { Outlet } from "react-router-dom";
import Navbar from "./Components/Navbar";

const Layout = () => {
    return (
        <div className="flex flex-col min-h-screen bg-background">
            {/* NavBar */}
            <Navbar/>
            <div className="flex flex-col flex-1 pt-24">
                {/* Main Content */}
                <main className="flex-1">
                    <Outlet />
                </main>
            </div>
            <Footer />
        </div>
    );
};

export default Layout;